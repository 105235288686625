// This file contains global styles that are not specific to a component.
@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import 'globals';

@font-face {
  font-family: Circular;
  src: url(/assets/ASCircularWeb-Book.woff) format("woff");
  font-weight: 400;
  font-style: normal
}

@font-face {
  font-family: Circular;
  src: url(/assets/ASCircularWeb-Bold.woff) format("woff");
  font-weight: 700;
  font-style: bold
}

body {
  font-family: $standard-font;
  margin: 0;
  padding: 0;
}

.btn-primary {
  color: $as-white;
  background-color: $button-bg-color;
  border-color: $button-bg-color;
  border-radius: 0 !important;

  .active,
  .focus,
  &:active,
  &:focus,
  &:hover,
  &:not(:disabled):not(.disabled):active {
    color: $as-white;
    background-color: $button-focus-bg-color;
    border-color: $button-focus-bg-color;
    transition: $button-ease;
  }

  &:focus,
  .focus,
  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, .35);
    outline: thin dotted !important;
    outline-offset: -2px;
  }

  &:disabled,
  .disabled {
    background-color: $medium-grey;
    border-color: $medium-grey;
  }
}

.grecaptcha-badge {
  display: none;
}

.custom-error>span {
  color: red;
  font-size: 12px;
}

.clear-button {
  border-radius: 0;
  float: inline-end;
  color: #2774ae;
  background: white;
  border-color: #2774ae;
}

.clear-button:hover {
  border-radius: 0;
  float: inline-end;
  color: #2774ae;
  background: white;
  border-color: #2774ae;
}

.clear-button:active {
  border-radius: 0;
  float: inline-end;
  color: #2774ae;
  background: white;
  border-color: #2774ae;
}