// This file contains reusable SASS functions and variables.

$standard-font: Circular, Helvetica Neue, Helvetica, Arial, sans-serif;

// Standard Colors
$as-medium-blue: #2774ae;
$as-dark-blue: #01426a;
$as-white: #fff;
$as-border-grey: #ccc;
$as-input-text: rgb(101, 101, 101);
$as-input-title-text: #656565;
$off-white: #e9e9e9;
$light-grey: #f5f5f5;
$medium-grey: #c8c9c7;
$darkish-grey: #7b7b7b;
$instructions: #3c3b3f;

$button-bg-color: #48850b;
$button-focus-bg-color: #3b6d09;

$error-background: #f2dede;
$error-text: #994442;

$wheat: #f9e595;

// Misc
$button-ease: all .5s ease;

.atlas-link {
  color: $as-medium-blue;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
